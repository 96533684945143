// LoadingBar.js
import React from 'react';

const LoadingBar = ({ progress }) => (
    <div className="w-full mt-6">
        <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
                <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-mint-DARK bg-yellow-100">
                        Loading
                    </span>
                </div>
                <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-mint-DARK">
                        {progress}%
                    </span>
                </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                <div
                    style={{ 
                        width: `${progress}%`,
                        transition: 'width 0.5s ease-in-out'
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-mint-DARK"
                >
                </div>
            </div>
        </div>
    </div>
);

export default LoadingBar;
