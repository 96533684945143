import React, { useState } from 'react';
import axios from 'axios';

function FinalScreen({ incentiveCode, sessionId }) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!email || !email.includes('@')) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await axios.post(`/api/complete/${sessionId}/claim`, { 
                email,
                incentiveCode 
            });
            
            if (response.status === 200) {
                setSuccess(true);
                setError(null);
            }
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to submit email. Please try again.');
            console.error('Email submission error:', err);
        }
    };

    if (!incentiveCode) {
        return <div>Error: No incentive code available</div>;
    }

    return (
        <div className="h-[calc(100vh-100px)] overflow-y-auto">
            <div className="relative space-y-6 p-6 mt-8 md:mt-12 sm:mt-8 mb-16 bg-white rounded-lg shadow-md mx-auto md:w-[37rem] sm:w-full">
                <h1 className="text-xl font-semibold">Thank you for completing the interview!</h1>
                <div className="mt-4">
                    <p className="font-medium">Your incentive code: <span className="font-bold text-lg">{incentiveCode}</span></p>
                    <p className="text-gray-600 mt-2">Please enter your email address to claim your incentive.</p>
                </div>
                <form onSubmit={handleEmailSubmit} className="mt-4">
                    <input 
                        type="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="mt-1 block w-full px-4 py-4 bg-BGGrey p-4 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                    />
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                    {success && <p className="text-green-500 mt-2">Email submitted successfully!</p>}
                    <button 
                        type="submit"
                        className="w-full flex bg-BGGrey-DARK justify-center mt-8 px-4 py-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-canary bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Submit Email
                    </button>
                </form>
            </div>
        </div>
    );
}

export default FinalScreen;
