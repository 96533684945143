import React, { useRef, useEffect } from 'react';
import LogoBlack from '../../icons/logoBlack';
import SendIcon from '../../icons/SendIcon';
import LoadingChat from '../../icons/LoadingChat';

const ChatInterface = ({ 
    messages, 
    inputText, 
    setInputText, 
    sendMessage, 
    completeInterview, 
    loading,
    isComplete 
}) => {
    const inputRef = useRef(null);
    const lastMessageRef = useRef(null);
    const chatMessagesRef = useRef(null);

    // Adjust textarea height based on content
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
        }
    }, [inputText]);

    // Scroll to the last message when messages update
    useEffect(() => {
        if (lastMessageRef.current && inputRef.current && chatMessagesRef.current) {
            const inputHeight = inputRef.current.offsetHeight;
            chatMessagesRef.current.scrollTo({
                top: chatMessagesRef.current.scrollHeight - inputHeight,
                behavior: 'smooth'
            });
        }
    }, [messages, loading]);

    // Handle Enter key to send message
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (inputText.trim()) {
                sendMessage(inputText);
                setInputText('');
            }
        }
    };

    // Update the completion check logic
    const shouldShowCompletion = React.useMemo(() => {
        if (messages.length < 2) return false;
        
        // Get last few messages
        const lastMessages = messages.slice(-4);
        let hasCompletionPhrase = false;

        lastMessages.forEach(msg => {
            if (msg.sender === 'bot') {
                const text = msg.text.toLowerCase();
                if (text.includes('this interview is complete') ||
                    text.includes('this concludes our interview') ||
                    text.includes('if you have any more thoughts, please reach out to') ||
                    text.includes('that concludes our interview') ||
                    (text.includes('thank you for participating') && 
                     text.includes('feedback is valuable'))) {
                    hasCompletionPhrase = true;
                }
            }
        });

        return hasCompletionPhrase || isComplete;
    }, [messages, isComplete]);

    // Completion banner component
    const CompletionBanner = () => (
        <div className="fixed top-0 left-0 right-0 bg-yellow-400 text-black py-4 px-6 text-center shadow-lg z-50 animate-fadeIn">
            <h3 className="text-lg font-semibold">Interview Complete! 🎉</h3>
            <p className="text-sm mb-3">Thank you for your participation</p>
            <button 
                onClick={completeInterview}
                className="mt-2 bg-black text-white py-2 px-6 rounded-full animate-pulse hover:bg-gray-800 font-semibold"
            >
                Complete Interview
            </button>
        </div>
    );

    const MessageBubble = ({ message }) => {
        if (message.isTyping) {
            return (
                <div className="message bg-chat-botBG text-black clear-both mt-4 relative float-left block max-w-80 p-4 rounded-md">
                    <LoadingChat />
                </div>
            );
        }

        return (
            <div
                className={`message ${
                    message.sender === 'bot' 
                        ? 'bg-chat-botBG text-black clear-both mt-4 relative float-left block max-w-80' 
                        : 'bg-chat-userBG text-black float-right clear-both relative block mb-4 max-w-80'
                } p-4 rounded-md`}
            >
                {message.text}
            </div>
        );
    };

    return (
        <div className="chat-interface container mx-auto relative flex flex-col md:w-[37rem] sm:w-full h-screen">
            {shouldShowCompletion && <CompletionBanner />}
            
            <div className='w-full flex items-center justify-between p-4'>
                <LogoBlack />
                {shouldShowCompletion && (
                    <button 
                        onClick={completeInterview} 
                        className="bg-canary hover:bg-yellow-600 text-black py-2 px-4 rounded-full animate-pulse">
                        Complete Interview
                    </button>
                )}
            </div>

            <div 
                ref={chatMessagesRef}
                className="chat-messages flex-1 overflow-y-auto space-y-4 p-4"
            >
                {messages.map((message, index) => (
                    <MessageBubble key={index} message={message} />
                ))}
                <div ref={lastMessageRef} />
            </div>

            <div className="chat-input w-full flex p-4 md:shadow-2xl bg-white md:rounded-md sm:rounded-none md:mb-4 sticky bottom-0">
                <textarea
                    ref={inputRef}
                    placeholder={shouldShowCompletion ? "Interview complete. Please click 'Complete Interview'" : "Type a message..."}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="flex-grow rounded-md py-1.5 pl-2 pr-20 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 resize-none overflow-hidden"
                    disabled={loading || shouldShowCompletion}
                    rows={1}
                    style={{ minHeight: '3rem', maxHeight: '10rem' }}
                />
                <button
                    onClick={() => {
                        if (inputText.trim()) {
                            sendMessage(inputText);
                            setInputText('');
                        }
                    }}
                    className={`block relative pt-3 pr-4 pl-5 pb-8 items-center float-right ml-4 ${
                        shouldShowCompletion ? 'bg-gray-300' : 'bg-canary hover:bg-yellow-600'
                    } h-4 text-black rounded-full`}
                    disabled={loading || shouldShowCompletion}
                >
                    <SendIcon />
                </button>
            </div>

            {shouldShowCompletion && (
                <div className="fixed bottom-0 left-0 right-0 text-center py-4 bg-yellow-100 border-t border-yellow-200 shadow-lg">
                    <p className="text-sm text-gray-600">
                        Please click 'Complete Interview' above to finish and receive your incentive code.
                    </p>
                </div>
            )}
        </div>
    );
};

export default ChatInterface;
