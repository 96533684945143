import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => {
    return (
        <div className="project-card bg-card-BG rounded-md text-black mt-4 float-right drop-shadow-none hover:drop-shadow-2xl border border-solid border-card-BG">
            <Link className="p-8 relative block" to={`/projects/${project._id}/detail`}>
                <span className='text-lightGrey text-xs'>ORGANISATION</span>
                <p className='text-black-TEXT R mb-4'>{project.organization}</p>
                <span className='text-lightGrey text-xs'>PROJECT</span>
                <h2 className='text-3xl mb-8 text-black-TEXT'>{project.name}</h2>
                <div className="text-canary float-right w-full relative text-right pb-8">
                    <span className='float-right'>
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3726 11.3138V13.3079L19.271 13.315L10.6655 21.9205L12.0797 23.3347L20.6852 14.7292L20.6923 22.6276H22.6863V11.3138H11.3726Z" fill="#121315"/>
                        </svg>
                    </span>
                </div>
            </Link>
        </div>
    );
};

export default ProjectCard;
