import React from 'react';

const PersonaIcon = ({ width = "24", height = "24", color = "black", className = "" }) => {
    return (
        <svg 
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 10H18" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M15 13H18" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11 10C11 11.1046 10.1046 12 9 12C7.89543 12 7 11.1046 7 10C7 8.89543 7.89543 8 9 8C9.53043 8 10.0391 8.21071 10.4142 8.58579C10.7893 8.96086 11 9.46957 11 10Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 15.9999C7.783 12.8629 11.052 13.8489 13 15.9999" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
};

export default PersonaIcon; 