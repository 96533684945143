import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PriceCard from './cards/PriceCard';
import { fetchUser } from '../actions'; 
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Pricing = ({ auth, history }) => {
    const dispatch = useDispatch();
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    useEffect(() => {
        fetchPrices();
    }, []);

    const fetchPrices = async () => {
        try {
            const {data} = await axios.get("/api/prices");
            console.log("Fetching prices: ", data);
            setPrices(data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching prices:", error);
            setLoading(false);
        }
    };

    const handleClick = async (e, price) => {
        e.preventDefault();
        if(!auth) {
            history.push("/register");
            return;
        }

        try {
            if(price.isOneOff) {
                const stripe = await stripePromise;
                if (!stripe) {
                    throw new Error('Stripe failed to initialize');
                }

                const {data} = await axios.post('/api/create-one-off-session');
                console.log('Session data:', data);

                const { error } = await stripe.redirectToCheckout({
                    sessionId: data.sessionId
                });

                if (error) {
                    throw error;
                }
            } else {
                const {data} = await axios.post('/api/create-subscription', {
                    priceID: price.id,
                    productID: price.product_id
                });
                window.open(data.url);
            }
        } catch (error) {
            console.error('Payment initiation error:', error);
            alert(`Failed to initiate payment: ${error.message}`);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='relative grid grid-cols-12 text-mint z-0'>
            <div className='col-span-12'>
                <h1>Explore the right plan for you</h1>
                <p>Choose a plan or try a single interview</p>
            </div>
            {prices && prices.map((price) => 
                <PriceCard 
                    key={price.id}
                    price={price}
                    handleSubscription={(e) => handleClick(e, price)}
                    userSubscription={auth?.subscription}
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(Pricing);
