import React from 'react';

const HomeIcon = ({ width = "24", height = "24", color = "black", className = "" }) => {
    return (
        <svg 
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
           <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6799 2.64685C11.3723 1.45117 8.62757 1.45117 6.31995 2.64685L3.99991 3.8439V10.8189C3.99991 10.8301 3.99966 10.8412 3.99917 10.8524C3.91941 12.6384 5.29401 14.1537 7.0771 14.2499H7.24991V12.777C7.21747 11.1654 8.49358 9.82942 10.1062 9.78916C10.1187 9.78885 10.1311 9.78885 10.1436 9.78916C11.7567 9.82942 13.033 11.1661 12.9999 12.7782V14.2449C14.7454 14.1098 16.0793 12.6126 16.0007 10.8524C16.0002 10.8412 15.9999 10.8301 15.9999 10.8189V3.8439L13.6799 2.64685ZM17.4999 4.61837V10.8029C17.6071 13.4119 15.5899 15.6212 12.9796 15.749C12.9674 15.7496 12.9551 15.7499 12.9429 15.7499H7.05791C7.04574 15.7499 7.03357 15.7496 7.02141 15.749C4.41065 15.6218 2.39277 13.4124 2.49991 10.8029V4.61837L1.34406 5.21531C0.976029 5.40538 0.5236 5.26111 0.333532 4.89308C0.143465 4.52504 0.287733 4.07262 0.655764 3.88255L2.89272 2.72728C2.90176 2.72238 2.91091 2.71766 2.92017 2.71312L5.62987 1.31501C5.63007 1.31491 5.62968 1.31511 5.62987 1.31501C8.36992 -0.104477 11.6293 -0.104779 14.3694 1.31471C14.3696 1.31481 14.3692 1.31461 14.3694 1.31471L17.0797 2.71312C17.0889 2.71765 17.0981 2.72238 17.1071 2.72728L19.3441 3.88255C19.7121 4.07262 19.8564 4.52504 19.6663 4.89308C19.4762 5.26111 19.0238 5.40538 18.6558 5.21531L17.4999 4.61837ZM2.5052 3.29742C2.50098 3.33226 2.49919 3.36749 2.49991 3.40285ZM8.74991 14.2499H11.4999V12.7699L11.5001 12.7521C11.5187 11.9695 10.9049 11.3188 10.1249 11.2893C9.34522 11.3188 8.73152 11.9691 8.74971 12.7515L8.74991 12.7689V14.2499Z" fill="black"/>
           </svg>
    );
};

export default HomeIcon; 