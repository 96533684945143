import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import ProjectCard from './ProjectCard';
import Modal from '../Modal';
import Alert from '../Alert';
import { fetchUser } from '../../actions';
import { Link } from 'react-router-dom';

function ProjectDashboard({ auth }) {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newProjectName, setNewProjectName] = useState('');
    const [organization, setOrganization] = useState('');
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const dispatch = useDispatch();

    // First, ensure auth is loaded
    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    // Then fetch projects when auth is available
    useEffect(() => {
        let mounted = true;

        const fetchProjects = async () => {
            try {
                const response = await axios.get('/api/projects');
                if (mounted) {
                    setProjects(response.data);
                    setLoading(false);
                }
            } catch (error) {
                if (mounted) {
                    console.error('Failed to fetch projects:', error);
                    setError('Failed to fetch projects');
                    setLoading(false);
                }
            }
        };

        if (auth) {
            fetchProjects();
        } else if (auth === null) {
            setLoading(false);
        }

        return () => {
            mounted = false;
        };
    }, [auth]);

    if (!auth) {
        return <div>Please log in to view projects</div>;
    }

    if (loading) {
        return <div>Loading projects...</div>;
    }

    const handleCreateProject = async () => {
        try {
            const response = await axios.post('/api/projects', { name: newProjectName, organization });
            if (response.status === 403 && response.data.canUpgrade) {
                setAlertMessage('You have reached your limit for creating new projects. Please upgrade your subscription to continue.');
                setIsAlertOpen(true);
                return;
            }
            setProjects([...projects, response.data]);
            setNewProjectName('');
            setOrganization('');
            setError(null);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Failed to create project:', error.response.data.error);
            setError(error.response.data.error);
        }
    };

    return (
        <div className="h-[calc(100vh-4rem)] ml-64 pt-16 flex flex-col rounded-tl-md bg-BGGrey">
            <div className="p-8 flex-none">
                {isAlertOpen && (
                    <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
                        <Alert
                            message={alertMessage}
                            type="error"
                            onClose={() => setIsAlertOpen(false)}
                        />
                    </Modal>
                )}
                <div className='w-full block relative'>
                    <div className='float-left'>
                        <h1 className='text-2xl text-black'>Your Projects</h1>
                    </div>
                    
                    <div className='mt-2 mb-2 float-right'>
                        <Link 
                            to="/interview/create"
                            className="bg-canary z-0 hover:bg-yellow-600 text-black py-4 px-4 rounded-full"
                        >
                            Create Interview
                        </Link>
                        {error && <div>Error: {error}</div>}
                    </div>
                </div>
            </div>
            <div className="flex-1 overflow-y-auto p-8 pt-0">
                <div className="project-cards grid grid-cols-4 gap-4">
                    {projects.map(project => (
                        <ProjectCard key={project._id} project={project} />
                    ))}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h2 className='text-xl font-bold mb-4'>Create New Project</h2>
                <input
                    type="text"
                    placeholder="Enter project name"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    className="block w-full mb-4 p-2 border border-gray-300 rounded-md"
                />
                <input
                    type="text"
                    placeholder="Enter organization name"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    className="block w-full mb-4 p-2 border border-gray-300 rounded-md"
                />
                <button onClick={handleCreateProject} className="bg-canary z-0 hover:bg-yellow-600 text-black py-2 px-4 rounded-full">
                    Create Project
                </button>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(ProjectDashboard);
