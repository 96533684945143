import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Logo from '../icons/logo';

const InterviewWizard = ({ auth }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [newProjectData, setNewProjectData] = useState({ name: '', organization: '' });
  const [objective, setObjective] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [generatedInterview, setGeneratedInterview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Add new state for editable interview data
  const [editableInterview, setEditableInterview] = useState({
    name: '',
    introduction: '',
    objective: '',
    topics: '',
    questions: []
  });

  // Add auth check effect
  useEffect(() => {
    if (!auth) {
      history.push('/login');
    }
  }, [auth, history]);

  // Add axios interceptor for auth token
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(config => {
      if (auth?.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      }
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          setError('Your session has expired. Please log in again.');
          history.push('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [auth, history]);

  useEffect(() => {
    // Fetch existing projects
    const fetchProjects = async () => {
      try {
        const response = await axios.get('/api/projects');
        setProjects(response.data);
      } catch (error) {
        setError('Failed to fetch projects');
      }
    };
    fetchProjects();
  }, []);

  // Move authentication check here, after all hooks
  if (!auth) {
    return <Redirect to="/login" />;
  }

  const handleProjectSelection = async (projectId) => {
    setSelectedProject(projectId);
    setStep(3); // Move to objective step
  };

  const handleCreateNewProject = async () => {
    try {
      const response = await axios.post('/api/projects', newProjectData);
      setSelectedProject(response.data._id);
      setStep(3);
    } catch (error) {
      setError('Failed to create project');
    }
  };

  const handleGenerateInterview = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/interviews/generate-interview', {
        objective,
        targetAudience
      });
      
      // Convert topics array to string if it comes as an array
      const topicsString = Array.isArray(response.data.topics) 
        ? response.data.topics.join('\n• ') 
        : response.data.topics;

      setGeneratedInterview(response.data);
      setEditableInterview({
        name: response.data.name,
        introduction: response.data.introduction,
        objective: response.data.objective,
        topics: topicsString,
        questions: response.data.questions
      });
      setStep(5);
    } catch (error) {
      if (error.response?.status === 403) {
        setError('Session expired. Please log in again.');
        history.push('/login');
      } else {
        setError('Failed to generate interview');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveInterview = async () => {
    if (!auth) {
      setError('Please log in to save your interview');
      history.push('/login');
      return;
    }

    try {
      // Ensure topics is a string
      const topicsString = Array.isArray(editableInterview.topics) 
        ? editableInterview.topics.join('\n• ')
        : editableInterview.topics;

      const script = `Introduction: ${editableInterview.introduction}\n\n` +
        `Objective: ${editableInterview.objective}\n\n` +
        `Topics: ${topicsString}\n\n` +
        `Questions:\n${editableInterview.questions.join('\n')}`;

      const response = await axios.post(
        `/api/interviews/projects/${selectedProject}/interviews`,
        {
          name: editableInterview.name || `Interview - ${new Date().toLocaleDateString()}`,
          introduction: editableInterview.introduction,
          objective: editableInterview.objective,
          topics: topicsString,
          questions: editableInterview.questions,
          script: script,
          interviewType: 'subscription'
        }
      );

      if (response.data) {
        history.push(`/projects/${selectedProject}/detail`);
      }
    } catch (error) {
      console.error('Failed to save interview:', error.response || error);
      if (error.response?.status === 403) {
        const limitError = error.response?.data;
        if (limitError?.error === "No available interviews") {
          setError(`You've reached your interview limit. Available interviews: 
            Subscription: ${limitError.limits.subscription.available}, 
            One-off: ${limitError.limits.oneOff.available}. 
            Please upgrade your subscription or purchase additional interviews.`);
        } else {
          setError('Session expired. Please log in again.');
          history.push('/login');
        }
      } else {
        setError('Failed to save interview: ' + (error.response?.data?.error || error.message));
      }
    }
  };

  // Update handler for editable fields
  const handleInterviewEdit = (field, value) => {
    setEditableInterview(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle question edits
  const handleQuestionEdit = (index, value) => {
    const newQuestions = [...editableInterview.questions];
    newQuestions[index] = value;
    setEditableInterview(prev => ({
      ...prev,
      questions: newQuestions
    }));
  };

  // Add new question
  const handleAddQuestion = () => {
    setEditableInterview(prev => ({
      ...prev,
      questions: [...prev.questions, '']
    }));
  };

  // Remove question
  const handleRemoveQuestion = (index) => {
    setEditableInterview(prev => ({
      ...prev,
      questions: prev.questions.filter((_, i) => i !== index)
    }));
  };

  // Add this function near your other handlers
  const handleCancel = () => {
    // If we have a selected project, go to that project's dashboard
    if (selectedProject) {
      history.push(`/projects/${selectedProject}/detail`);
    } else {
      // If no project is selected, go to the main projects dashboard
      history.push('/projects');
    }
  };

  return (
    <div className="h-screen flex flex-col bg-BGGrey-HEADER">
      {/* Header with logo */}
      <div className='justify-center mb-2 items-center'> 
        <Link to="/projects" className="justify-center items-center text-center rounded-lg px-2 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900">
          <Logo width="88" height="34" />
        </Link>
      </div>

      {/* Progress bar section - fixed */}
      <div className="p-6 pb-0 mx-auto text-white w-3/5">
        <div className="mb-8 w-full">
          <div className="flex justify-between mb-2">
            {['Project Selection', 'Objective', 'Target Audience', 'Review & Edit'].map((label, index) => (
              <div key={label} className={`text-sm ${step === index + 1 ? 'text-mint' : 'text-gray-500'}`}>
                {label}
              </div>
            ))}
          </div>
          <div className="h-2 bg-black rounded">
            <div 
              className="h-full bg-mint rounded transition-all duration-300"
              style={{ width: `${(step / 4) * 100}%` }}
            />
          </div>
        </div>
      </div>

      {/* Scrollable content area */}
      <div className="flex-1 p-6 pt-0">
        <div className="max-w-4xl mx-auto">
          {/* Step containers */}
          {/* Step 1: Project Selection */}
          {step === 1 && (
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Create New Interview</h2>
                <button
                  onClick={handleCancel}
                  className="text-gray-500 hover:text-gray-700"
                >
                  Cancel
                </button>
              </div>
              <p className="mb-6">First, let's select or create a project for this interview.</p>
              <button 
                onClick={() => setStep(2)}
                className="bg-mint text-white px-4 py-2 rounded-full hover:bg-mint-dark mb-6"
              >
                Create New Project
              </button>
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-4">Or select existing project:</h3>
                {projects.map(project => (
                  <button
                    key={project._id}
                    onClick={() => handleProjectSelection(project._id)}
                    className="block w-full text-left p-4 mb-2 border rounded hover:border-mint hover:bg-gray-50"
                  >
                    {project.name} - {project.organization}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Step 2: Create New Project */}
          {step === 2 && (
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Create New Project</h2>
                <button
                  onClick={handleCancel}
                  className="text-gray-500 hover:text-gray-700"
                >
                  Cancel
                </button>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Project Name</label>
                  <input
                    type="text"
                    value={newProjectData.name}
                    onChange={(e) => setNewProjectData({...newProjectData, name: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Organization</label>
                  <input
                    type="text"
                    value={newProjectData.organization}
                    onChange={(e) => setNewProjectData({...newProjectData, organization: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint"
                  />
                </div>
                <button
                  onClick={handleCreateNewProject}
                  className="bg-mint text-white px-4 py-2 rounded-full hover:bg-mint-dark"
                >
                  Create Project
                </button>
              </div>
            </div>
          )}

          {/* Step 3: Objective */}
          {step === 3 && (
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">What is the objective of your interview?</h2>
                <button
                  onClick={handleCancel}
                  className="text-gray-500 hover:text-gray-700"
                >
                  Cancel
                </button>
              </div>
              <textarea
                value={objective}
                onChange={(e) => setObjective(e.target.value)}
                placeholder="e.g., To understand user pain points with our current checkout process..."
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint h-32"
              />
              <button
                onClick={() => setStep(4)}
                disabled={!objective.trim()}
                className="mt-4 bg-mint text-white px-4 py-2 rounded-full hover:bg-mint-dark disabled:opacity-50"
              >
                Next
              </button>
            </div>
          )}

          {/* Step 4: Target Audience */}
          {step === 4 && (
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Who is the target audience of your interview?</h2>
                <button
                  onClick={handleCancel}
                  className="text-gray-500 hover:text-gray-700"
                >
                  Cancel
                </button>
              </div>
              <textarea
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
                placeholder="e.g., Online shoppers aged 25-45 who have made at least 3 purchases in the last month..."
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint h-32"
              />
              <button
                onClick={handleGenerateInterview}
                disabled={!targetAudience.trim() || isLoading}
                className="mt-4 bg-mint text-white px-4 py-2 rounded-full hover:bg-mint-dark disabled:opacity-50"
              >
                {isLoading ? 'Generating...' : 'Generate Interview Script'}
              </button>
            </div>
          )}

          {/* Step 5: Review and Edit */}
          {step === 5 && editableInterview && (
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Review and Edit Interview Script</h2>
                <button
                  onClick={handleCancel}
                  className="text-gray-500 hover:text-gray-700"
                >
                  Cancel
                </button>
              </div>
              
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Interview Name</label>
                  <input
                    type="text"
                    value={editableInterview.name}
                    onChange={(e) => handleInterviewEdit('name', e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Introduction</label>
                  <textarea
                    value={editableInterview.introduction}
                    onChange={(e) => handleInterviewEdit('introduction', e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint h-32"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Objective</label>
                  <textarea
                    value={editableInterview.objective}
                    onChange={(e) => handleInterviewEdit('objective', e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint h-24"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Topics</label>
                  <textarea
                    value={editableInterview.topics}
                    onChange={(e) => handleInterviewEdit('topics', e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint h-24"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Questions</label>
                  {editableInterview.questions.map((question, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                      <textarea
                        value={question}
                        onChange={(e) => handleQuestionEdit(index, e.target.value)}
                        className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-mint focus:ring-mint"
                      />
                      <button
                        onClick={() => handleRemoveQuestion(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={handleAddQuestion}
                    className="mt-2 text-mint hover:text-mint-dark"
                  >
                    + Add Question
                  </button>
                </div>
              </div>

              <div className="mt-6 flex justify-between gap-4">
                <button
                  onClick={() => setStep(4)}
                  className="text-gray-600 hover:text-gray-800"
                >
                  ← Back
                </button>
                <button
                  onClick={handleSaveInterview}
                  className="bg-mint text-white px-6 py-2 rounded-full hover:bg-mint-dark"
                >
                  Save Interview
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="mt-4 p-4 bg-red-50 text-red-600 rounded">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(InterviewWizard); 