import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import UserInformationForm from './chatComps/UserInformationForm';
import ConsentScreen from './chatComps/ConsentScreen';
import ChatInterface from './chatComps/ChatInterface';
import FinalScreen from './chatComps/FinalScreen';
import LogoBlack from '../icons/logoBlack';
import LoadingBar from './elements/LoadingBar';

function ChatSession() {
    const { interviewId } = useParams();
    const history = useHistory();
    const [sessionId, setSessionId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [chatReady, setChatReady] = useState(false);
    const [chatStarted, setChatStarted] = useState(false);
    const [interviewComplete, setInterviewComplete] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [userInfoCollected, setUserInfoCollected] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [inputText, setInputText] = useState('');
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [incentiveCode, setIncentiveCode] = useState(null);
    const [initializing, setInitializing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [completionState, setCompletionState] = useState({
        allQuestionsAsked: false,
        finalResponseReceived: false,
        completionMessageSent: false,
        isComplete: false,
        lastFourMessagesPattern: {
            messages: [],
            pattern: 'none'
        }
    });
    const [organizationLoading, setOrganizationLoading] = useState(true);
    const [isInitiating, setIsInitiating] = useState(false);

    useEffect(() => {
        const fetchOrganization = async () => {
            setOrganizationLoading(true);
            try {
                const response = await axios.get(`/api/interviews/${interviewId}`);
                console.log('Fetched interview data:', response.data);
                
                let orgName;
                if (response.data && response.data.organization) {
                    orgName = response.data.organization;
                } else if (response.data && response.data.script) {
                    orgName = "Canary Insights";
                }
                
                if (!orgName) {
                    throw new Error('Organization information not found');
                }
                
                setOrganization(orgName);
                setOrganizationLoading(false);
            } catch (error) {
                console.error('Failed to fetch organization:', error);
                setError('Failed to load interview details');
                setOrganizationLoading(false);
            }
        };

        if (interviewId) {
            fetchOrganization();
        }
    }, [interviewId]);

    useEffect(() => {
        console.log('Organization state:', organization);
        console.log('Loading state:', organizationLoading);
    }, [organization, organizationLoading]);

    useEffect(() => {
        let mounted = true;

        const startChat = async () => {
            if (consentGiven && userInfoCollected && userInfo && !isInitiating) {
                setIsInitiating(true);
                try {
                    await initiateChatSession(userInfo);
                } catch (error) {
                    if (mounted) {
                        setError(error.message || 'Failed to start chat session');
                    }
                } finally {
                    if (mounted) {
                        setIsInitiating(false);
                    }
                }
            }
        };

        startChat();

        return () => {
            mounted = false;
        };
    }, [consentGiven, userInfoCollected, userInfo]);

    // Check session status periodically
    useEffect(() => {
        let statusInterval;
        if (sessionId && !interviewComplete) {
            statusInterval = setInterval(async () => {
                try {
                    const response = await axios.get(`/api/sessions/${sessionId}/status`);
                    const { isComplete, completionMarkers, currentPhase } = response.data;
                    
                    // Only set completion if we're past introduction and have a proper completion
                    if (isComplete && 
                        currentPhase !== 'introduction' && 
                        completionMarkers?.lastFourMessagesPattern?.hasCompletionPhrase) {
                        setCompletionState(prevState => ({
                            ...prevState,
                            ...completionMarkers,
                            isComplete: true
                        }));
                    }
                } catch (error) {
                    console.error('Failed to check session status:', error);
                }
            }, 5000);
        }
        return () => clearInterval(statusInterval);
    }, [sessionId, interviewComplete]);

    const handleUserInformationSubmit = async (formData) => {
        if (!formData || !formData.ageRange || !formData.occupation || 
            !formData.location || !formData.townCity) {
            setError('All user information fields are required');
            return;
        }
        
        setUserInfo(formData);
        setUserInfoCollected(true);
    };

    const handleBeginChat = () => {
        if (!isConsentChecked) {
            setError('Please check the consent box to continue');
            return;
        }
        setConsentGiven(true);
    };

    const initiateChatSession = async (userInfoData) => {
        console.log('Initiating chat with user info:', userInfoData);
        
        if (!userInfoData || !userInfoData.ageRange || !userInfoData.occupation || 
            !userInfoData.location || !userInfoData.townCity) {
            throw new Error('Missing required user information');
        }

        setInitializing(true);
        setProgress(0);
        
        try {
            // Start progress animation
            let progressInterval = setInterval(() => {
                setProgress(prev => {
                    if (prev >= 90) {
                        clearInterval(progressInterval);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 500);

            const response = await axios.post(`/api/sessions/${interviewId}`, {
                consentGiven,
                userInfo: userInfoData
            });

            const { sessionId: newSessionId, message, threadId } = response.data;
            
            // Only update state if we got a valid response
            if (newSessionId && message) {
                setSessionId(newSessionId);
                setMessages([{ text: message, sender: 'bot', type: 'introduction' }]);
                setChatStarted(true);
                setChatReady(true);
                
                // Complete the progress bar
                clearInterval(progressInterval);
                setProgress(100);
                
                // Give a small delay before removing the loading screen
                setTimeout(() => {
                    setInitializing(false);
                }, 500);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Failed to initiate chat:', error);
            setProgress(0);
            throw error;
        }
    };

    const sendMessage = async (messageText) => {
        if (!sessionId || loading) return;
        setLoading(true);

        try {
            // Optimistically add user message immediately
            const userMessage = { text: messageText, sender: 'user' };
            setMessages(prevMessages => [...prevMessages, userMessage]);
            setInputText('');

            // Show typing indicator immediately
            const typingMessage = { text: '', sender: 'bot', isTyping: true };
            setMessages(prevMessages => [...prevMessages, typingMessage]);

            const response = await axios.post(`/api/sessions/${interviewId}/${sessionId}/send`, {
                message: messageText
            });

            const { botResponse, isComplete, currentPhase } = response.data;
            
            // Simplified completion check focusing on specific phrases
            const isCompletionMessage = 
                botResponse.toLowerCase().includes('have a great day') ||
                (botResponse.toLowerCase().includes('thank you') && 
                 botResponse.toLowerCase().includes('feedback is valuable'));

            if (isCompletionMessage) {
                setCompletionState(prevState => ({
                    ...prevState,
                    isComplete: true,
                    completionMessageSent: true,
                    completionTimestamp: new Date()
                }));
            }

            // Update messages
            setMessages(prevMessages => {
                const filtered = prevMessages.filter(msg => !msg.isTyping);
                return [...filtered, { 
                    text: botResponse, 
                    sender: 'bot', 
                    type: isCompletionMessage ? 'completion' : 'core'
                }];
            });

        } catch (error) {
            // Remove typing indicator on error
            setMessages(prevMessages => prevMessages.filter(msg => !msg.isTyping));
            console.error('Failed to send message:', error);
            setError('Failed to send message');
        } finally {
            setLoading(false);
        }
    };

    const completeInterview = async () => {
        if (!sessionId) return;
        
        try {
            // First, ensure we're in completion state
            if (!completionState.isComplete) {
                setError('Interview must be complete before finalizing');
                return;
            }

            const response = await axios.post(
                `/api/sessions/${interviewId}/${sessionId}/complete`,
                {
                    lastFourMessages: messages.slice(-4).map(msg => ({
                        text: msg.text,
                        sender: msg.sender,
                        type: msg.type
                    }))
                }
            );
            
            if (response.data.success && response.data.incentiveCode) {
                setIncentiveCode(response.data.incentiveCode);
                setInterviewComplete(true);
                setCompletionState(prevState => ({
                    ...prevState,
                    ...response.data.completionState,
                    isComplete: true
                }));
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Failed to complete interview:', error);
            setError(error.response?.data?.error || 'Failed to complete interview');
            // Optionally show more detailed error
            if (error.response?.data?.details) {
                console.error('Error details:', error.response.data.details);
            }
        }
    };
    
    if (error) {
        return (
            <div className="h-screen flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto">
                    <div className="text-center mx-auto md:w-[37rem] sm:w-full">
                        <LogoBlack />
                        <p className="text-red-500 mt-4">{error}</p>
                    </div>
                </div>
            </div>
        );
    }

    if (organizationLoading) {
        return (
            <div className="h-screen flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto">
                    <div className="text-center mx-auto md:w-[37rem] sm:w-full">
                        <LogoBlack />
                        <p className="text-lg mt-4">Loading interview details...</p>
                    </div>
                </div>
            </div>
        );
    }

    if (!organization) {
        return (
            <div className="h-screen flex flex-col overflow-hidden">
                <div className="flex-1 overflow-y-auto">
                    <div className="text-center mx-auto md:w-[37rem] sm:w-full">
                        <LogoBlack />
                        <p className="text-red-500 mt-4">Organization information not found</p>
                    </div>
                </div>
            </div>
        );
    }

    if (!consentGiven) {
        return (
            <div className="h-screen flex flex-col">
                <div className="mx-auto md:w-[37rem] sm:w-full">
                    <LogoBlack />
                </div>
                <ConsentScreen
                    organization={organization}
                    isConsentChecked={isConsentChecked}
                    setIsConsentChecked={setIsConsentChecked}
                    onConsent={handleBeginChat}
                    error={error}
                />
            </div>
        );
    }

    if (consentGiven && !userInfoCollected) {
        return   <div className="h-screen flex flex-col">
                    <div className="mx-auto md:w-[37rem] sm:w-full">
                        <LogoBlack />
                    </div>
                    <UserInformationForm onSubmit={handleUserInformationSubmit} />
                </div>;
    }

    if (interviewComplete) {
        return   <div className="h-screen flex flex-col">
                    <div className="mx-auto md:w-[37rem] sm:w-full">
                        <LogoBlack />
                    </div>
                    <FinalScreen incentiveCode={incentiveCode} sessionId={sessionId} />
                </div>;
    }

    if (initializing) {
        return (
            <div className="h-screen flex flex-col">
                <div className="text-center">
                    <div className="mx-auto md:w-[37rem] sm:w-full">
                        <LogoBlack />
                    </div>
                    <div className="mx-auto md:w-[37rem] sm:w-full items-center">
                        <p className="text-lg mt-4">Starting your interview with {organization}…</p>
                        <LoadingBar progress={progress} />
                    </div>
                  
                </div>
            </div>
        );
    }

    return (
        <ChatInterface
            messages={messages}
            inputText={inputText}
            setInputText={setInputText}
            sendMessage={sendMessage}
            completeInterview={completeInterview}
            loading={loading}
            isComplete={completionState.isComplete}
        />
    );
}

export default ChatSession;
