import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchUser, updateSubscription } from '../actions';



const Profile = ({ auth }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await axios.get('/api/subscription-status');
        dispatch(updateSubscription(response.data[0])); // Assuming the first item is the most recent subscription
        setLoading(false); // Set loading to false after the data is fetched and dispatched
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setLoading(false); // Ensure loading is set to false even if there is an error
      }
    };
  
    fetchSubscriptionData();
  }, [dispatch]);
  

  if (!auth || loading) {
    return <div>Loading...</div>;
  }
  

  console.log('Auth data:', auth); // Check what is actually in the auth object

  const subscription = auth.subscription;

  console.log('Subscription: ', subscription)
  console.log('Auth Subscription: ', auth.subscription)

  const manageSubscriptions = async () => {
    const { data } = await axios.get("/api/manage-subscription");
    window.open(data);
  };

  const handleCancelSubscription = async () => {
    const { data } = await axios.post('/api/cancel-subscription');
    alert(data.message);
  };

  const handleChangeSubscription = async (newPlanId) => {
    const { data } = await axios.post('/api/change-subscription', { newPlanId });
    alert(data.message);
  };

  const handleDeleteAccount = async () => {
    const confirmMessage = 
        'Are you sure you want to delete your account?\n\n' +
        'Your account will be permanently deleted, but any projects and research data you\'ve created will be preserved.\n\n' +
        'This action cannot be undone.';

    if (window.confirm(confirmMessage)) {
        try {
            const response = await axios.delete('/api/delete-account');
            alert('Your account has been successfully deleted.');
            window.location.href = '/';
        } catch (error) {
            console.error('Error deleting account:', error);
            alert(
                'Failed to delete account: ' + 
                (error.response?.data?.error || 'Please try again later.')
            );
        }
    }
  };

  return (
    <div className="profile-container mx-auto z-0 relative bg-BGGrey-DARK text-mint p-8 ml-64">
      {auth.profilePic ? (
        <img src={auth.profilePic} alt="Profile" className="profile-picture" />
      ) : (
        <div className="fallback-profile-picture">
          {auth.firstName && auth.lastName ? `${auth.firstName[0]}${auth.lastName[0]}` : ''}
        </div>
      )}
      
      {auth.firstName && auth.lastName && (
        <>
          <h2>{auth.firstName} {auth.lastName}</h2>
          <p>Email: {auth.email}</p>
        </>
      )}

      <div>
        <h2>Current limits</h2>
        <div>
          <p>Current Interview usage: {auth.currentInterviews || 0} </p>
          <p>Current Session usage: {auth.currentSessions || 0}</p>
          <p>Subscription Interview limits: {auth.interviewLimits || 0}</p>
          <p>Session limits: {auth.sessionLimits || 0}</p>
          <p>Available One-off Interviews: {auth.availableOneOffInterviews || 0}</p>
        </div>
        <h2>Subscription</h2>
        {auth.subscription ? (
          <div>
           <p>Plan: {auth.subscription.plan.name}</p>
           <p>Status: {auth.subscription.status}</p>
            <p>Price: £{(auth.subscription.plan.amount / 100).toFixed(2)}</p>
            <p>Renews: Every {auth.subscription.plan.interval}</p>
            <p>Card: **** **** **** {auth.subscription.default_payment_method.card.last4}</p>
            <p>Current period ends: {moment.unix(auth.subscription.currentPeriodEnd).format("MMMM Do YYYY")}</p>
            {auth.subscription.canceled_at && (
              <p>Canceled at: {moment.unix(auth.subscription.canceled_at).format("MMMM Do YYYY")}</p>
            )}
            <button onClick={handleCancelSubscription}>Cancel Subscription</button>
            <button onClick={() => handleChangeSubscription('new_plan_id')}>Change Plan</button>
            <button><a href="/api/logout">Logout</a></button>
            <button 
              onClick={manageSubscriptions}
              className='items-center justify-center w-full px-8 py-3 mb-4 overflow-hidden border border-slate-700  rounded-md cursor-pointer'>
                
                Manage subscription
            </button>
          </div>
        ) : (
          <div>
            <p>No active subscription</p>
            <Link to="/pricing">View Pricing</Link>
          </div>
        )}
      </div>

      <div>
        <h2>Current Usage</h2>
        <div>
            <h3>Subscription Usage</h3>
            <p>Interviews Used: {auth.currentSubscriptionInterviews || 0} / {auth.subscriptionInterviewLimit || 0}</p>
            <p>Sessions Used: {auth.currentSubscriptionSessions || 0} / {auth.subscriptionSessionLimit || 0}</p>
            
            <h3>One-off Purchases</h3>
            <p>Available Interviews: {auth.availableOneOffInterviews || 0}</p>
            <div>
                {auth.oneOffPurchases?.map((purchase, index) => (
                    <div key={index} className="my-2 p-2 border border-slate-700 rounded">
                        <p>Purchase Date: {moment(purchase.purchaseDate).format("MMMM Do YYYY")}</p>
                        <p>Status: {purchase.used ? 'Used' : 'Available'}</p>
                        {purchase.interviewId && (
                            <Link to={`/interviews/${purchase.interviewId}`}>
                                View Interview
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
      </div>

      <div className="mt-8 border-t border-slate-700 pt-8">
        <h2 className="text-xl font-semibold mb-4">Account Management</h2>
        <div className="flex flex-col space-y-4">
          <a 
            href="/api/logout"
            className="px-4 py-2 bg-slate-600 text-white rounded hover:bg-slate-700 text-center transition-colors"
          >
            Logout
          </a>
          
          <button
            onClick={handleDeleteAccount}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
          >
            Delete Account
          </button>
        </div>
      </div>

      <div className="mt-4 text-sm text-slate-400">
        <p>* Deleting your account will preserve any projects and research data you've created, 
        but will permanently remove your personal information and access to the platform.</p>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(Profile);
