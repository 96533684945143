import React from 'react';

const IntegrationsIcon = ({ width = "24", height = "24", color = "black", className = "" }) => {
    return (
        <svg 
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
           <path fill-rule="evenodd" clip-rule="evenodd" d="M3 0.25C2.58579 0.25 2.25 0.585786 2.25 1V2.25H1C0.585786 2.25 0.25 2.58579 0.25 3V9C0.25 9.41421 0.585786 9.75 1 9.75H3.25V11C3.25 11.4142 3.58579 11.75 4 11.75H4.25V15C4.25 15.4142 4.58579 15.75 5 15.75C5.41421 15.75 5.75 15.4142 5.75 15V11.75H6C6.41421 11.75 6.75 11.4142 6.75 11V9.75H9C9.41421 9.75 9.75 9.41421 9.75 9V3C9.75 2.58579 9.41421 2.25 9 2.25H7.75V1C7.75 0.585786 7.41421 0.25 7 0.25H3ZM6.25 2.25V1.75H3.75V2.25H6.25ZM5.25 9.75V10.25H5H4.75V9.75H5.25ZM6 8.25H8.25V3.75H7H3H1.75V8.25H4H6Z" fill="black"/>
           </svg>
    );
};

export default IntegrationsIcon; 