// LoginPage.js
import React from 'react';
import { Link} from 'react-router-dom';
import Logo from './icons/logo';

const SignUpPage = () => {
    return (
        <div className="justify-center min-h-screen bg-BGGrey-HEADER">
            <div className='m-auto w-300 relative justify-center items-center'>
                <div className='justify-center mb-32 items-center'> 
                    <Link
                            to="/"
                            className=" justify-center items-center text-center rounded-lg px-2 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900"
                        >
                            <div className='m-auto w-full relative justify-center items-center '>
                                <div className='w-300 h-300 m-auto block'>
                                    <Logo />
                                </div>
                            </div> 

                    </Link>

                </div>
                <div className="relateive m-auto p-8 rounded-lg shadow-lg w-full max-w-md h-300">
                    <h2 className="text-2xl mb-6 text-center text-white">Log In</h2>
                    <div className="space-y-4">
                        <a href="/auth/google">
                            <button
                                className="w-full py-3 px-6 bg-red-500 text-black rounded-lg bg-canary transition-colors mb-4"
                            >
                                <svg className='float-left' fill="#000000" width="32px" height="32px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5_logos</title><path d="M473.16,221.48l-2.26-9.59H262.46v88.22H387c-12.93,61.4-72.93,93.72-121.94,93.72-35.66,0-73.25-15-98.13-39.11a140.08,140.08,0,0,1-41.8-98.88c0-37.16,16.7-74.33,41-98.78s61-38.13,97.49-38.13c41.79,0,71.74,22.19,82.94,32.31l62.69-62.36C390.86,72.72,340.34,32,261.6,32h0c-60.75,0-119,23.27-161.58,65.71C58,139.5,36.25,199.93,36.25,256S56.83,369.48,97.55,411.6C141.06,456.52,202.68,480,266.13,480c57.73,0,112.45-22.62,151.45-63.66,38.34-40.4,58.17-96.3,58.17-154.9C475.75,236.77,473.27,222.12,473.16,221.48Z"/></svg>
                                Log in with Google
                            </button>
                        </a>
                        <a href="/auth/microsoft">
                            <button
                                className="w-full py-3 px-6 bg-canary text-black rounded-lg hover:bg-blue-600 transition-colors"
                            >
                                <svg className='float-left' fill="#000000" width="32px" height="32px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M31.87,30.58H244.7V243.39H31.87Z"/><path d="M266.89,30.58H479.7V243.39H266.89Z"/><path d="M31.87,265.61H244.7v212.8H31.87Z"/><path d="M266.89,265.61H479.7v212.8H266.89Z"/></svg>
                                Log in with Microsoft
                            </button>
                        </a>
                        <p className='pt-8 relative block text-white w-full text-center'>Do not have an account? <Link className="text-canary"
                            to="/signup">Sign up now</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
