// app.js
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Headerapp from './Header';
import Profile from './Profile';
import Landing from './Landing';
import Pricing from './Pricing';
import Register from './Register';
import StripeSuccess from './stripe-success';
import StripeCancel from './stripe-cancel';
import AnalyzeTranscript from './AnalyzeTranscript';
import InterviewSetup from './interviewer/InterviewSetup';
import ProjectCreation from './interviewer/ProjectCreation';
import ProjectDashboard from './interviewer/ProjectDashboard';
import ChatSession from './interviewer/ChatSession';
import ProjectDetail from './interviewer/ProjectDetail';
import InterviewCompletion from './interviewer/InterviewCompletion';
import ChatbotCreationForm from './interviewer/ChatbotCreationForm'; // Import the new component
import InterviewDetail from './interviewer/InterviewDetail';
import PersonaDetail from './interviewer/PersonaDetail';
import PracticeSessionsPage from './interviewer/PracticeSessionsPage';
import SimulatedInterviewPage from './interviewer/SimulatedInterview';
import SimulatedInterviewDetailPage from './interviewer/SimulatedInterviewResults';
import OnboardingForm from './OnboardingForm';
import LoginPage from './Login';
import SignUpPage from './signup';
import InterviewWizard from './interviewer/InterviewWizard';
import Alert from './Alert';

// Define routes where the header should not be shown
const noHeaderRoutes = [
    '/login', 
    '/register', 
    '/onboarding', 
    '/signup',
    '/interviews/' // Add this to hide header on interview detail pages
];

class App extends Component {
  state = {
    error: '',
    loading: true
  };

  // Add helper function for initials
  getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  render() {
    const { error } = this.state;
    const { loading, location, auth } = this.props;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="h-screen overflow-hidden bg-BGGrey ">
        <div className="h-full">
          <Headerapp />
          
          {/* Main Content Area - Full height minus header */}
          <div className="h-[calc(100%-4rem)] bg-BGGrey ">
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route path="/profile" component={Profile} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/register" component={Register} />
              <Route path="/loginX250821" component={LoginPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/signupX250821" component={SignUpPage} />
              <Route path="/onboarding" component={OnboardingForm} />
              <Route path="/stripe/cancel" component={StripeCancel} />
              <Route path="/stripe/success" component={StripeSuccess} />
              <Route path="/analyze-transcript" component={AnalyzeTranscript} />
              <Route path="/project/create" component={ProjectCreation} />
              <Route exact path="/projects" component={ProjectDashboard} />
              <Route path="/create-interview" component={InterviewSetup} />
              <Route path="/projects/:projectId/detail" component={ProjectDetail} />
              <Route path="/chat/:interviewId" component={ChatSession} />
              <Route path="/chat/:interviewId/:sessionId" component={ChatSession} />
              <Route path="/chat/interview-complete" component={InterviewCompletion} />
              <Route path="/projects/:projectId/chatbot/create" component={ChatbotCreationForm} />
              <Route path="/interviews/:interviewId" component={InterviewDetail} />
              <Route path="/personas/:personaId" component={PersonaDetail} />
              <Route path="/simulated-interview-detail/:sessionId" component={SimulatedInterviewDetailPage} />
              <Route path="/simulated-interview/interview/:sessionId" component={SimulatedInterviewPage} />
              <Route path="/practice-sessions" component={PracticeSessionsPage} />
              <Route path="/interview/create" component={InterviewWizard} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

// Update mapStateToProps to include loading state
function mapStateToProps({ auth }) {
  return { 
    auth,
    loading: false // You might want to manage this through Redux instead
  };
}

// Make sure to wrap with withRouter first, then connect
export default withRouter(connect(mapStateToProps, actions)(App));
