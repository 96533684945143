// Header.js
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Logo from './icons/logo';

function Header({ auth, location }) {
    // Define routes where the header should not be shown
    const noHeaderRoutes = ['/', '/login', '/register', '/onboarding', '/signup', '/loginX250821', '/signupX250821', '/interview/create'];
    const hideHeaderOnChat = /^\/chat\/[a-zA-Z0-9]+$/;

    if (noHeaderRoutes.includes(location.pathname) || hideHeaderOnChat.test(location.pathname)) {
        return null;
    }

    const getInitials = (firstName, lastName) => {
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    return (
        <>
            {/* Top Black Header */}
            <div className="fixed top-0 left-0 right-0 h-16 bg-BGGrey-HEADER  flex justify-between items-center px-4 z-50">
                <Link to="/projects" className="flex items-center pl-4">
                    <Logo className="h-8 w-auto" fillColor="#FFFFFF" />
                </Link>
                
                <div className="flex items-center space-x-4 pr-4">
                    <Link 
                        to="/pricing" 
                        className="text-white text-sm px-4 py-2 rounded-full hover:bg-canary hover:text-black"
                    >
                        Upgrade
                    </Link>
                    <Link 
                        to="/profile"
                        className="flex items-center space-x-2 text-white hover:text-mint"
                    >
                        {auth?.profilePic ? (
                            <img 
                                src={auth.profilePic} 
                                alt="Profile" 
                                className="h-8 w-8 rounded-full ring-2 ring-white" 
                            />
                        ) : (
                            <div className="h-8 w-8 rounded-full bg-gray-700 ring-2 ring-white flex items-center justify-center">
                                {getInitials(auth?.firstName, auth?.lastName)}
                            </div>
                        )}
                        <span className="font-medium">{auth?.name}</span>
                    </Link>
                </div>
            </div>

            {/* Left Side Navigation */}
            <div className="w-64 bg-BGGrey shadow-lg h-screen fixed left-0 top-16 flex flex-col">
                <nav className="flex-1 mt-6">
                    <Link 
                        to="/projects"
                        className="w-full flex items-center px-6 py-3 text-BGGrey-HEADER hover:bg-gray-700 hover:text-white"
                    >
                        <span className="mr-3">🏠</span>
                        <span className="font-medium">Home</span>
                    </Link>

                    <Link 
                        to="/profile"
                        className="w-full flex items-center px-6 py-3 text-BGGrey-HEADER hover:bg-gray-700 hover:text-white"
                    >
                        <span className="mr-3">👤</span>
                        <span className="font-medium">My Profile</span>
                    </Link>
                </nav>

                <div className="p-4 border-t border-gray-700">
                    <a 
                        href="/api/logout"
                        className="w-full flex items-center px-6 py-3 text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                        <span className="mr-3">🚪</span>
                        <span className="font-medium">Logout</span>
                    </a>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default withRouter(connect(mapStateToProps)(Header));
