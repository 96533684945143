import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserInformationForm = ({ onSubmit }) => {
    const [ageRange, setAgeRange] = useState('');
    const [occupation, setOccupation] = useState('');
    const [location, setLocation] = useState('');
    const [townCity, setTownCity] = useState('');
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countryNames = response.data.map(country => country.name.common).sort();
            setCountries(countryNames);
        };
        fetchCountries();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Validate all fields are filled
        if (!ageRange || !occupation || !location || !townCity) {
            alert('All fields are required');
            return;
        }

        // Create the user info object
        const userInfoData = {
            ageRange,
            occupation: occupation.trim(),
            location: location.trim(),
            townCity: townCity.trim()
        };

        console.log('Submitting user info:', userInfoData); // Debug log
        onSubmit(userInfoData);
    };

    return (
        <div className="h-[calc(100vh-100px)] overflow-y-auto">
            <form onSubmit={handleSubmit} className="space-y-6 p-6 mt-16 bg-white rounded-lg shadow-md mx-auto md:w-[37rem] sm:w-full">
                {/* Age Range Field */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Age Range*</label>
                    <select
                        value={ageRange}
                        onChange={(e) => setAgeRange(e.target.value)}
                        required
                        className="mt-1 block w-full px-2 py-2 bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="">Select age range</option>
                        <option value="18-24">18-24</option>
                        <option value="25-34">25-34</option>
                        <option value="35-50">35-50</option>
                        <option value="50-65">50-65</option>
                        <option value="65+">65+</option>
                    </select>
                </div>

                {/* Location Field */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Country*</label>
                    <select
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        required
                        className="mt-1 block w-full px-2 py-2 bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="">Select country</option>
                        {countries.map(country => (
                            <option key={country} value={country}>{country}</option>
                        ))}
                    </select>
                </div>

                {/* Occupation Field */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Occupation:</label>
                    <input
                        type="text"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        required
                        className="mt-1 block w-full  px-2 py-2  bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Town/City Field */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Town/City:</label>
                    <input
                        type="text"
                        value={townCity}
                        onChange={(e) => setTownCity(e.target.value)}
                        required
                        className="mt-1 block w-full  px-2 py-2  bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full flex bg-BGGrey-DARK justify-center mt-8 px-4 py-4  border border-transparent rounded-full shadow-sm text-sm font-medium text-canary bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Start interview
                </button>
            </form>
        </div>
    );
};

export default UserInformationForm;
