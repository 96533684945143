import React from 'react';
import { connect } from 'react-redux';
import Checkmark from '../icons/IconCheckmark';


const PriceCard = ({ auth, price, handleSubscription, userSubscription }) => {

    const buttonStyle = () => {
        if (isCurrentPlan) {
            return "bg-gray-400";  // Grey out the button if it's the current plan
        }
        if (price.isOneOff) {
            return "bg-green-500";
        }
        return price.nickname === "BASIC" ? "bg-slate-grey" : "bg-sky-500";  // Original logic for button color
    }

    const headerStyle = () => {
        if (price.isOneOff) {
            return "bg-green-600 text-white";
        }
        return price.nickname === "PREMIUM" ? "bg-red-600 text-white" : "text-white";
    }

    const borderStyle = () => {
        return price.nickname === "PREMIUM" ? "drop-shadow-2xl" : " ";
    }

    const dynamicFeatures = () => {
        if (price.isOneOff) {
            return (
                <div className='mt-8 mb-8'>
                    <p>This includes: </p>
                    <ul>
                        <li className='block text-left'>
                            <span className="float-left block mr-4">
                                <Checkmark fillColor="#FFFFFF" width="20" height="20"/>
                            </span>
                            1 Interview
                        </li>
                        <li className='block text-left'>
                            <span className="float-left block mr-4">
                                <Checkmark fillColor="#FFFFFF" width="20" height="20"/>
                            </span>
                            50 Participant sessions
                        </li>
                        <li className='block text-left'>
                            <span className="float-left block mr-4">
                                <Checkmark fillColor="#FFFFFF" width="20" height="20"/>
                            </span>
                            No subscription required
                        </li>
                    </ul>
                </div>
            );
        } else if (price.name === 'Basic Plan') {
            return (
                <div className='mt-8 mb-8'>
                     <p>This includes: </p>
                    <ul>
                    <li className='block text-left'>
                            <span className="float-left block mr-4" ><Checkmark fillColor="#FFFFFF" width="20" height="20"/></span>
                            Unlimited projects
                        </li>
                        <li className='block text-left'>
                            <span className="float-left block mr-4" ><Checkmark fillColor="#FFFFFF" width="20" height="20"/></span>
                            Unlimited projects
                        </li>
                        <li className='block text-left'>
                            <span className="float-left block mr-4" ><Checkmark fillColor="#FFFFFF" width="20" height="20"/></span>
                            Unlimited projects
                        </li>
                    </ul>
                </div>
            );
        } else if (price.name === 'Pro Plan') {
            return (
               <div className='mt-8 mb-8'>
                <p>This includes: </p>
                    <ul>
                        <li className='block text-left'>
                            <span className="float-left block mr-4" ><Checkmark fillColor="#FFFFFF" width="20" height="20"/></span>
                            Unlimited projects
                        </li>
                        <li className='block text-left'>
                            <span className="float-left block mr-4" ><Checkmark fillColor="#FFFFFF" width="20" height="20"/></span>
                            Unlimited projects
                        </li>
                        <li className='block text-left'>
                            <span className="float-left block mr-4" ><Checkmark fillColor="#FFFFFF" width="20" height="20"/></span>
                            Unlimited projects
                        </li>
                    </ul>
                </div>
            );
        }
    }
    const buttonText = () => {
        if (price.isOneOff) {
            return !auth ? "Sign up" : "Buy single interview";
        }
        if (userSubscription && Object.keys(userSubscription).length > 0) {
            const userCurrentPlan = userSubscription.planName;
            return price.nickname === userCurrentPlan ? "Current plan" : "Upgrade";
        }
        return !auth ? "Sign up" : "Buy plan";
    }

    

    const isCurrentPlan = !price.isOneOff && buttonText() === "Current plan";  // Determine whether the current plan is the user's active subscription

    return (
        <div className='col-span-4 z-o bg-BGGrey-DARK rounded-md mr-4'>
                    
        <div className={`relative gap-4 bg-slate-900 text-white mt-16 mr-4 p-8 space-y-4 drop-shadow-md hover:drop-shadow-2xl rounded-md min-w-[200px] ${borderStyle()}`}>
            <div className='card-header'>
                <div className={`${headerStyle()}`}>
                    <h2 className='text-xl font-bold mb-4'>{price.name}</h2>
                </div>

                <p className='block relative h-24'>{price.description}</p>
            </div>
            <div className='card-body'>
                <span>{price.price}<small>/mo</small></span>
                <div className='min-h-4'>
                    {dynamicFeatures()}
                </div>

              
                <button
                    onClick={(e) => handleSubscription(e, price)}
                    disabled={isCurrentPlan}  // Set disabled attribute based on whether this is the user's current plan
                    className={
                        `items-center justify-center w-full px-8 py-3 mb-4 overflow-hidden border border-slate-700  rounded-md cursor-pointer ${buttonStyle()}`
                    } 
                >
                    {buttonText()}
                </button>
                
            </div>
            <div className='card-footer'>
                
            </div>

        </div>

    </div>

    )
}

const mapStateToProps = ({ auth }) => {
    return { auth };
  };
  

export default connect(mapStateToProps)(PriceCard);