import React from 'react';

const OverviewIcon = ({ width = "24", height = "24", color = "black", className = "" }) => {
    return (
        <svg 
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M12 19C15.866 19 19 15.866 19 12H12V5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.333 9.6669V5.3999C16.3253 6.10716 17.8927 7.67462 18.6 9.6669H14.333Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default OverviewIcon; 