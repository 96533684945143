import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ChatbotCard from './ChatbotCard';
import { Link, useHistory } from 'react-router-dom';
import Modal from '../Modal';
import Alert from '../Alert';

function ProjectDetailPage({ auth, match }) {
    const [project, setProject] = useState(null);
    const [chatbots, setChatbots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            if (!auth) return;

            const projectId = match.params.projectId;
            try {
                const [projectResponse, chatbotsResponse] = await Promise.all([
                    axios.get(`/api/projects/${projectId}`),
                    axios.get(`/api/projects/${projectId}/chatbots`)
                ]);
                
                setProject(projectResponse.data);
                setChatbots(chatbotsResponse.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setAlert({ message: 'Failed to fetch data', type: 'error' });
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [auth, match.params.projectId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleCreateChatbotClick = async (useOneOff = false) => {
        if (!auth) return;

        if (!useOneOff && auth?.currentSubscriptionInterviews >= auth?.subscriptionInterviewLimit) {
            setAlert({ message: 'You have reached your subscription interview limit. Please upgrade your subscription.', type: 'error' });
        } else {
            history.push(`/projects/${match.params.projectId}/chatbot/create${useOneOff ? '?useOneOff=true' : ''}`);
        }
    };

    const deleteProject = async () => {
        setLoading(true);
        try {
            await axios.delete(`/api/projects/${match.params.projectId}`);
            setAlert({ message: 'Project deleted successfully!', type: 'success' });
            history.push('/projects');
        } catch (error) {
            console.error('Failed to delete project:', error);
            setAlert({ message: 'Failed to delete project', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmDelete = () => {
        setIsModalOpen(false);
        deleteProject();
    };

    return (
        <div className="h-[calc(100vh-4rem)] ml-64 flex flex-col pt-16 rounded-tl-md bg-BGGrey">
            <div className="p-8 flex-none">
                <div className='mb-6'>
                    {project && (
                        <div className='w-full relative block'>
                            <div className='text-BGGrey-BORDER text-left float-left w-full block relative clear-both text-xs'>
                                <Link className="float-left" to={`/projects`}>All projects</Link>
                                <span className="float-left ml-2 mt-1">
                                    {/* SVG here */}
                                </span>
                                <span className='text-lightGrey float-left ml-2 '>{project.name} detail</span>
                            </div>
                            <div className='relative block w-full float-left clear-both mt-8'>
                                <div className='mb-12 text-black-TEXT float-left block relative clear-both'>
                                    <p className='mb-2'>{project.organization}</p>
                                    <h2 className='text-4xl mb-4'>{project.name}</h2>
                                    <p className='text-sm'>Created At: {new Date(project.createdAt).toLocaleDateString()}</p>
                                </div>
                                <div className='float-right'>
                                    {alert.message && <Alert message={alert.message} type={alert.type} />}
                                    <button onClick={() => handleCreateChatbotClick(false)} className="bg-canary hover:bg-yellow-600 text-black py-2 px-4 rounded-full shadow-lg">
                                        Create New Chatbot
                                    </button>
                                    <button onClick={handleDeleteClick} disabled={loading} className="ml-2 bg-red-500 text-alertRed py-2 px-4 rounded">
                                        {loading ? 'Deleting...' : 'Delete Project'}
                                    </button>
                                </div>
                            </div>
                            {alert.message && (
                                <div className={`alert ${alert.type === 'error' ? 'alert-danger' : 'alert-success'}`}>
                                    {alert.message}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <h2 className='text-black-TEXT'>Interviews</h2>
            </div>
            <div className="flex-1 overflow-y-auto p-8 pt-0">
                {auth?.oneOffPurchases?.some(p => p.status === 'available') && (
                    <div className="mb-4 p-4 border border-yellow-400 rounded bg-yellow-50">
                        <h3 className="font-bold">Available One-off Interview Slots</h3>
                        <div className="flex justify-between items-center mt-2">
                            <p>You have {auth.oneOffPurchases.filter(p => p.status === 'available').length} one-off interview(s) available</p>
                            <button 
                                onClick={() => handleCreateChatbotClick(true)}
                                className="bg-yellow-500 hover:bg-yellow-600 text-black py-2 px-4 rounded-full shadow-lg"
                            >
                                Use One-off Slot
                            </button>
                        </div>
                    </div>
                )}

                <div className="chatbot-list">
                    {chatbots.map(chatbot => (
                        <ChatbotCard 
                            key={chatbot._id} 
                            chatbot={chatbot}
                            isOneOff={chatbot.interviewType === 'oneOff'}
                        />
                    ))}
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <div className="p-6">
                    <h2 className="text-xl font-bold mb-4">Warning - Delete Project</h2>
                    <p className="mb-4">
                        You are about to delete a project. This will also delete all of your interviews and associated data. Are you sure?
                    </p>
                    <div className="flex justify-end">
                        <button onClick={handleCloseModal} className="bg-gray-300 text-gray-800 py-2 px-4 rounded mr-2">
                            Cancel
                        </button>
                        <button onClick={handleConfirmDelete} className="bg-red-500 text-white py-2 px-4 rounded">
                            Delete Project
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(ProjectDetailPage);
